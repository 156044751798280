*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.topnav{
  height: 40px;
  background-color: rgba(6, 74, 114, 1);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
  justify-content: space-between;
}
.topnav ul {
  display: flex;
  flex-direction: row;
  gap: 30px;
  margin-bottom: 0px;
  padding-left: 0px;
}
.topnav ul li{
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  gap: 10px;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.nav{
  height: 80px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 50px !important;
  padding-right: 50px;
}
.nav ul{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.nav ul li{
  list-style: none;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 500;
}
.overlay{

  background-image: url(./Assets/Baku-1-1920x960.jpg);
}
.banner{
  background-color: rgba(0, 0, 0, 0.424);
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 200px;
  padding-bottom: 200px;
  background-size: cover;
  display: flex;
  flex-direction: row;
}
.banner-left{
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;

}
.banner-right{
width: 30%;
}
.book-form{
  width: 100%;
  background-color: white;
  padding: 30px;
  border-radius: 20px;
}
.book-form h3{
  text-align: center;
  margin-bottom: 10px;
  color: rgba(6, 74, 114, 1);
  font-size: 30px;
  font-weight: 700;
  font-family: "Manrope", sans-serif;

}
.book-form input{
  width: 100%;
  margin-bottom: 15px; 
  height: 40px;
  padding-left: 20px;

  border-radius: 8px;
  border: 1px solid black;
  font-family: "Manrope", sans-serif;

 
}
.book-form button{
  width: 100%;
background-color: rgba(253, 206, 11, 1);
border-radius: 12px;
height: 40px;
border: 1px solid rgba(253, 206, 11, 1);
font-family: "Manrope", sans-serif;
font-size: 15px;
font-weight:700;

}
.disc-div{
  Width:
200.38px;
Height:
52px;
background-color: rgba(253, 206, 11, 1);
border-radius: 50px;
display: flex;
justify-content: center;
align-items: center;
font-family: "Manrope", sans-serif;


}
.disc-div p{
  font-family: "Manrope", sans-serif;
  font-size: 16px !important;
  font-weight: 700 !important;
color: black !important;

}
.banner-left h2{
  font-family: "Manrope", sans-serif;
  font-size: 61px;
  font-weight: 800;
  color: white;
}
.banner-left p{
  font-family: "Manrope", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: white;
}
.banner-right img{
  border-radius: 16px;
  border: 3px solid white;
}
.banner-right {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 30%;
}
.PopularDestinations h2{
  text-align: center;
  font-family: "Manrope", sans-serif;
  font-size: 52px;
  font-weight: 800;
  color: rgba(6, 74, 114, 1);
}
.PopularDestinations p{
  font-family: "Manrope", sans-serif;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: rgba(115, 115, 115, 1);
}

.PopularDestinations-card{
  Width:
288px;
/* Height:
223.31px; */
border: 1px solid rgba(228, 230, 232, 1);
display: flex;
/* justify-content: center;
align-items: center; */
flex-direction: column;
text-align: start;
padding: 20px;
border-radius: 24px;
}
.PopularDestinations-card img{
  height: 150px;
  border-radius: 24px;
}
.PopularDestinations-card h3{
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-weight: 700;
}
.PopularDestinations-card p{
  text-align: left;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: rgba(115, 115, 115, 1);
}
.PopularDestinations-cards{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  padding: 30px;
}
.specialpackges h2{
  font-family: "Manrope", sans-serif;
  font-size: 52px;
  font-weight: 800;
  color: rgba(6, 74, 114, 1);

}
.specialpackges{
  padding: 30px;
}
.specialpackges p{
  font-family: "Manrope", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: rgba(115, 115, 115, 1);

}
.specialpackges-card{
  width: 40%;
  /* height: 612px; */
  border-radius: 24px;
  display: flex;
  flex-direction: row;

  gap: 30px;


}
.specialpackges-details{
  background-color: white;
  margin-left: -50px;
  border-radius: 24px;
  padding: 30px;
  border: 1px solid rgba(228, 230, 232, 1);

}
.specialpackges-card img{
  border-radius: 24px 0 0 24px;
  /* height: 100%; */
}
.specialpackges-details h3{
  font-family: "Manrope", sans-serif;
  font-size: 15px;
  font-weight: 700;
}
.luxury{
  Width: 70px;
Height:
28px;
background-color: rgba(255, 240, 236, 1);
border: 1px solid rgba(228, 230, 232, 1);
border-radius: 16px;
display: flex;
justify-content: center;
align-items: center;

}
.luxury p{
  font-family: "Manrope", sans-serif;
  font-size: 12px !important;
  font-weight: 700;
  color: black;
  margin-bottom: 0px;

}
.locat{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 0px;
  color: rgba(115, 115, 115, 1);
  font-size: 16px !important;
  font-weight: 500;
  font-family: "Manrope", sans-serif;

}
.locat i{
color: rgba(214, 215, 216, 1);
}
.specialpackges-details h4{
  font-size: 24px;
  font-weight: 800;
  font-family: "Manrope", sans-serif;
  color: black;

}
.specialpackges-details h4 span{
  font-size: 16px;
  font-weight: 500;
  font-family: "Manrope", sans-serif;
  color: rgba(115, 115, 115, 1);
}
.specialpackges-details button{
  /* float: inline-end; */
  background-color: rgba(253, 206, 11, 1);
  border: 1px solid rgba(228, 230, 232, 1);
  border-radius: 50px;
  font-size: 14px;
  font-weight: 700;
  font-family: "Manrope", sans-serif;
  color: black;
  Width:
104.14px;
Height:
40px;
}
.viewplan{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.specialpackges-cards{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* align-items: center; */
  flex-wrap: wrap;
  gap: 50px;
}
.whyus{
  padding: 50px;
  display: flex;
  flex-direction: row;

}

.whyus-left{
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
}
.whyus-right{
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 50px;

}
.WhyChooseUs{
  Width:
151.75px;
Height:
48px;
background-color: rgba(253, 206, 11, 1);
color: black;
border-radius: 50px;

display: flex;
justify-content: center;
align-items: center;
}
.WhyChooseUs{
  font-size: 14px;
  font-weight: 700;
  font-family: "Manrope", sans-serif;
}
.whyus-left h2{
  font-size: 52px;
  font-weight: 800;
  font-family: "Manrope", sans-serif;
  color: rgba(6, 74, 114, 1);

}
.whyus-left h4{
  color: rgba(115, 115, 115, 1);
  font-size: 20px;
  font-weight: 500;
  font-family: "Manrope", sans-serif;

}
.de-div{
  Width:
288px;
Height:
275px;
background-color: rgba(6, 74, 114, 1);
border-radius: 32px;
}
.de1{
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 100px;
}
.de2{
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.de-div1{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  gap: 10px;
}
.de-div1 h3{
  font-size: 20px;
  font-weight: 700;
  font-family: "Manrope", sans-serif;
  color: white;
}
.de-div1 p{
  font-size: 14px;
  font-weight: 500;
  font-family: "Manrope", sans-serif;
  color: white;
}
.de-div3{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  gap: 10px;
  background-color: white;
  border: 1px solid rgba(228, 230, 232, 1);

}
.de-div3 h3{
  font-size: 20px;
  font-weight: 700;
  font-family: "Manrope", sans-serif;
}
.de-div3 p{
  font-size: 14px;
  font-weight: 500;
  font-family: "Manrope", sans-serif;
  color: rgba(142, 142, 142, 1);
}
.gallery{
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
}
.gallery h2{
  font-size: 44px;
  font-weight: 800;
  font-family: "Manrope", sans-serif;
  color: white;
  text-align: center;
}
.testimonials{
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 247, 216, 1);
  padding-top: 50px;
  padding-bottom: 50px;
}
.testimonials-left{
  width: 100%;
  padding: 50px;
}
.testimonials-right{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 50px;
  overflow: auto !important;
  padding-left: 30px;
  padding-right: 30px;
}
.testimonials-left h2{
  font-size: 52px;
  font-weight: 800;
  font-family: "Manrope", sans-serif;
  color: rgba(6, 74, 114, 1);
}
.testimonials-left p{
  font-size: 18px;
  font-weight: 500;
  font-family: "Manrope", sans-serif;
  color: rgba(115, 115, 115, 1);
}
.review{
  width: 450px;
  background-color: white;
  border-radius: 32px;
  border: 1px solid rgba(228, 230, 232, 1);
  padding: 30px;
}
.review-heading{
  border-bottom: 1px solid rgba(228, 230, 232, 1);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding-bottom: 20px;

}
.review-heading h3{
  font-size: 18px;
  font-weight: 700;
  font-family: "Manrope", sans-serif;
}
.review p{
  color: rgba(115, 115, 115, 1);
  font-size: 14px;
  font-weight: 400;
  font-family: "Manrope", sans-serif;
}
.footer{
  background-color: black;
  padding: 30px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer h3{
  color: white;
  font-size: 16px;
  font-weight: 500;
  font-family: "Manrope", sans-serif;
  display: flex;
  align-items: center;
}
.footer h3 span{
  color: rgba(253, 206, 11, 1);
  font-size: 24px;
  font-weight: 800;
  font-family: "Manrope", sans-serif;
  margin-left: 10px;
}
.bottom-footer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: black;
  padding: 10px 50px;
}
.bottom-footer ul{
  display: flex;
  flex-direction: row;
  color: white;
  gap: 50px;

}
.bottom-footer ul li{
  list-style: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Manrope", sans-serif;
}
.bottom-footer h2{
  font-size: 14px;
  font-weight: 500;
  font-family: "Manrope", sans-serif;
  color: white;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  /* width: 400px; */
  max-width: 100%;
  height: auto;
  padding: 30px;
  font-family: "Manrope", sans-serif;

}

.modal-content h2 {
  margin-top: 0;
}

.modal-content button {
  margin-top: 20px;
}
.modal-content h2{
   font-size: 21px;
  font-weight: 800;
  font-family: "Manrope", sans-serif;
  color: rgba(6, 74, 114, 1);

}
.modal-content img{
  width: 100%;
  height: 300px;
}
.modal-content ul li{
  list-style: none;
  margin-bottom: 10px;
  font-size: 12px;
}
.modal-content p{
  font-size: 15px;
  font-weight: 400;
}
.close{
  background-color: rgba(253, 206, 11, 1);
  padding: 10px 30px;
  border-radius: 50px;
  border: none;
  color: rgb(7, 7, 7);
  font-weight: 700;
}
.gallery-div {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  grid-template-rows: repeat(2, 1fr); /* 2 rows */
  gap: 20px; /* Remove gap between grid items */
}

.gallery-div img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire grid cell */
  margin: 0; /* Remove any default margin */
  padding: 0; /* Remove any default padding */
  border-radius: 20px;
}
.calls{
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;

}
.calls p{
  font-size: 18px;
  font-weight: 500;
  font-family: "Manrope", sans-serif;
}
.callicon{
  background-color: white;
  height: 40px;
  width: 40px;
  border: 1px solid black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

}
.callicon i{
  margin-top: -5px;
  margin-bottom: 0px;
  color: rgba(6, 74, 114, 1);
}
.calls img{
  height: 40px;
  border: 1px solid black;
  border-radius: 50%;
}
@media(max-width:768px){
  .whyus-left h2{
  font-size: 35px;

  }
  .whyus{
    padding: 30px;
  }
  .gallery h2{
  font-size: 25px;

  }
  .topnav{
    display: none;
  }
.calls{
gap: 10px;
}
.nav{
  height: 80px;
  padding-left: 20px !important;
  padding-right: 20px;
}
.calls p{
  font-size: 15px;
  font-weight: 500;
  font-family: "Manrope", sans-serif;
}

.calls img{
  height: 40px;
  border: 1px solid black;
  border-radius: 50%;
}
.banner-left{
  width: 100%;
}
.banner{
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 100px;
  padding-bottom: 100px;
}
.gallery-div {
  grid-template-columns: repeat(2, 1fr); /* 3 columns */
  grid-template-rows: repeat(3, 1fr); /* 2 rows */
}
}
@media(max-width:600px){
  .banner-left h2{
    font-size: 40px;
    font-weight: 800;
  }
  .banner-left p{
  font-size: 18px;

  }
.banner{
  background-position: right;
}
}
@media(max-width:1250px){
.PopularDestinations-cards{
  overflow: auto;
  justify-content: start;
}
.PopularDestinations h2{
  font-size: 40px;

}
.specialpackges-card{
  width: 35%;
}
.specialpackges-cards{
  gap: 20px;

}
}
@media(max-width:1150px){
.specialpackges-cards{
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.specialpackges-card{
  width: 100%;
}

}

@media(max-width:1350px){
  .whyus{
  display: flex;
  flex-direction: column;
  }
  .whyus-left{
    width: 100%;
  }
  .whyus-right{
    width: 100%;
  }
}
@media(max-width:1350px){
  .whyus-right{
    display: flex;
    flex-direction: column;

  }
}
@media(max-width:600px){

  .gallery{
    padding: 30px;
  }
}
@media(max-width:768px){
  .testimonials-right{
    justify-content: start;
  gap: 20px;

  }
  .testimonials-left h2{
  font-size: 35px;

  }
.testimonials-left p{
  font-size: 15px;

}
.testimonials-left{
  padding: 30px;
}
.footer{
flex-direction: column;
justify-content: start;
align-items: start;

}
.bottom-footer{
  flex-direction: column-reverse;
  gap: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.specialpackges h2{
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

}
.specialpackges p{
  font-size: 15px;

}
}
.plan-cards{

}
.plan-card{
  width: 30%;
  position: relative;

  border-radius: 24px;
  border: 1px solid rgba(228, 230, 232, 1);
}
.plan-card img{
  width: 100%;
  height: 200px;
  border-radius: 24px 24px 0px 0px;
}
.plan-text{
  padding: 30px;


}
.plan-text h2{
  font-size: 20px;
}
.viewplan button{
  width: 50%;
  height: 40px;
  


}
.viewdtl{
  background-color: rgba(253, 206, 11, 1);
  border: 1px solid rgba(253, 206, 11, 1);
  font-weight: 700;
  font-family: "Manrope", sans-serif;
  color: white;

}
.booknow{
  border: 3px solid rgba(253, 206, 11, 1);
  background: none;
  color: rgba(253, 206, 11, 1);
  font-weight: 700;

}

/* .viewplan button{
  padding: 15px 25px;
  border-radius: 30px;
  border: none;
  background-color: rgba(253, 206, 11, 1);
  font-size: 15px;
  font-weight: 600;

} */
.plan-cards-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
}
@media(max-width:1000px){
.plan-card{
  width: 45%;
}
.plan-cards-container{
  justify-content: center;
}
}
@media(max-width: 700px){
  .plan-card{
    width: 100%;
  }
  .plan-card{
    padding: 20px;
  }
  /* .viewplan button{
    padding: 10px 15px;
  font-size: 15px;

  } */
}

p{
  margin-bottom: 0px !important;
}

.rates{
  background-color: rgba(253, 206, 11, 1);
/* width: 50%; */
position: absolute;
top: 0px;
right: 0px;
border-radius: 0px 24px 0px 0px;
padding: 5px 15px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;

}
.rates h4{
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 0rem;
}
.Ips{
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 30px;
}
.Ips input{
  width: 100%;
  height: 40px;
  padding-left: 20px;
  border-radius: 12px;
  border: 1px solid rgba(6, 74, 114, 1);
  
}
.cont{
  color: rgba(6, 74, 114, 1);
  font-size: 18px;
  font-weight: 500;
}
textarea{
  width: 100%;
  padding-left: 20px;
  border-radius: 12px;
  border: 1px solid rgba(6, 74, 114, 1);
  padding-top: 20px;
}
.modalbook{
  width: 100%;
  height: 50px;
  border-radius: 12px;

  background-color: rgba(253, 206, 11, 1);
  border: 1px solid rgba(253, 206, 11, 1);
  font-size: 18px;
  font-weight: 700;
  color: white;

}
@media(max-width: 700px){
.Ips{
  flex-direction: column;
  gap: 20px;
}

}
.bakuimg{
  width: 100%;
  display: flex;
  flex-direction: row;
}
.bakuimg img{
  width: 25%;

}
@media(max-width: 1200px){
  .banner-left{
    width: 60%;
  }
  .banner-right{
    width: 40%;
    }

}
@media(max-width: 800px){
  .banner{
    flex-direction: column;
    gap: 50px;
    background-position: center;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .banner-left{
    width: 100%;
  }
  .banner-right{
    width: 100%;
    }
}
.bottom-footer ul{
  padding-left: 0rem !important;


}
@media(max-width: 700px){
.bottom-footer{
  padding: 10px 10px;

}
.bottom-footer ul{
  padding-left: 0rem !important;
  gap: 20px;

}
.footer{
  padding: 30px 20px;

}

.footer h3 span{
  font-size: 20px;

}

}
.footer ul{
  padding-left: 0rem !important;
  
}

.whatsapp{
  position: fixed;
  height: 60px;
  right: 8%;
  bottom: 8%;
  z-index: 100;
}
.thankyou{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}
.thankyou h1{
  font-size: 50px;
  font-weight: 800;
  font-family: "Manrope", sans-serif;
  color: rgba(6, 74, 114, 1);
}
.thankyou p{
  font-size:20px;
  font-weight: 500;
  font-family: "Manrope", sans-serif;
}
.booknow1{
  float: inline-end;
  padding: 10px 15px;
  border-radius: 10px;
  background-color: rgba(253, 206, 11, 1);
  color: white;
}